(function ($) {
  var owl, ajaxUrl, lastAjaxUrl;
  $(document).foundation();

  // Variables
  var defaultDocTitle, defaultDocClass, docTitle, docClass;
  var currentDocTitle = null;
  var currentDocClass = null;

  // Change browser URL
  function changeBrowserURL(content, title, href) {
    var content_object = {};
    if (content !== null) {
      content_object = { content: content };
    } else {
      content_object = null;
    }

    if (history.pushState) {
      history.pushState(content_object, title, href);
    } else {
      document.location.hash = href;
    }
  }

  function ajaxPage(href) {
    $('#js-content').html('');
    $('.project-header').addClass('project-header--loading');

    if (currentDocTitle !== null) {
      currentDocTitle = docTitle;
    } else {
      currentDocTitle = defaultDocTitle;
    }

    if (currentDocClass !== null) {
      currentDocClass = docClass;
    } else {
      currentDocClass = defaultDocClass;
    }

    $.ajax({
      url: href,
      type: 'GET',
      data: null,
      success: function (data) {
        var findContent = $(data).find('#js-content');
        findContent = findContent[0].innerHTML;
        $('#js-content').html(findContent);

        var findWrapper = $(data).filter('#wrapper');
        findWrapper = findWrapper[0].innerHTML;

        var parser = new DOMParser();
        var doc = parser.parseFromString(data, 'text/html');
        docClass = doc.body.getAttribute('class');
        var docTitle = doc.title;
        parser = doc = null;
        document.title = docTitle;
        $('body').attr('class', docClass);

        changeBrowserURL(findWrapper, docTitle, href);
      },
      complete: function (data) {
        lastAjaxUrl = href;
        $('.project-header').removeClass('project-header--loading');
      },
      cache: false,
    });
  }

  // Scroll to anchors
  // $(document).on('click', 'a[href*=#]', function(event){
  // event.preventDefault();
  // var headerHeight = $('.global-header').outerHeight();
  //
  // if( this.hash.length < 1 ) {
  // $('html,body').animate({
  // scrollTop: 0
  // }, 500);
  // } else {
  // $('html,body').animate({
  // scrollTop: $(this.hash).offset().top - headerHeight
  // }, 500);
  // }
  // });

  function fullHeight() {
    var windowHeight = $(window).height();
    var correctedHeight = windowHeight;
    $('.js-full-height').css({
      height: correctedHeight,
    });
  }

  function defaultDocProperties() {
    defaultDocTitle = document.getElementsByTagName('title')[0].innerHTML;
    defaultDocClass = $('body').attr('class');

    var defaultContent = $('body').find('#wrapper');
    defaultContent = defaultContent[0].innerHTML;
    var href = window.location.href;

    var content_object = {};
    content_object = { content: defaultContent };

    history.replaceState(content_object, defaultDocTitle, href);
  }

  function docReadyFunctions() {
    fullHeight();
    owl = $('.owl-carousel');

    owl.owlCarousel({
      items: 1,
      nav: false,
      loop: true,
      navText: ['', ''],
    });

    owl.on('changed.owl.carousel', function (event) {
      var index = event.item.index;
      ajaxUrl = $('.owl-item')
        .eq(index)
        .children('.project-header__item')
        .data('url');
    });

    owl.on('translated.owl.carousel', function (event) {
      if (lastAjaxUrl !== ajaxUrl) {
        ajaxPage(ajaxUrl);
        $(window).scrollTop(0);
      }
    });

    if ($('body').hasClass('home')) {
      owl.trigger('to.owl.carousel', [0, 0]);
    } else {
      position = $('.project-header__item--active').data('index');
      owl.trigger('to.owl.carousel', [position, 0]);
    }

    $('.owl-nav--custom .owl-next').click(function () {
      owl.trigger('next.owl.carousel');
    });

    $('.owl-nav--custom .owl-prev').click(function () {
      owl.trigger('prev.owl.carousel');
    });
  }

  $(document).on('click', '.menu-icon', function (event) {
    $(this).toggleClass('menu-icon--active');
  });

  $(document).ready(function () {
    defaultDocProperties();
    lastAjaxUrl = $('.project-header__item--active').data('url');

    docReadyFunctions();
  });

  $(window).resize(function () {
    fullHeight();
  });
  (function () {
    if ($('body').hasClass('single-projects') || $('body').hasClass('home')) {
      var $window = $(window);
      $window.scroll(function () {
        var windowHeight = $(window).height(),
          bodyHeight = $('body').height(),
          footerHeight = $('footer.footer').height(),
          opacity,
          number,
          rounded;

        var currentScroll = $(document).scrollTop();

        if (
          bodyHeight - (currentScroll + windowHeight * 2 + footerHeight) <=
          0
        ) {
          number =
            (currentScroll - (bodyHeight - windowHeight * 2 + footerHeight)) /
            (windowHeight / 1.5);
          rounded = Math.round(number * 100) / 100;
          opacity = 0 + rounded;
        } else {
          number = currentScroll / (windowHeight / 1.5);
          rounded = Math.round(number * 100) / 100;
          opacity = 1 - rounded;
        }

        if (opacity >= 0 && opacity <= 1) {
          $('.project-header__carousel').css('opacity', opacity);
        }

        if (
          bodyHeight - (currentScroll + windowHeight * 2 + footerHeight) <=
          0
        ) {
          $('.project-header').removeClass('project-header--hidden');
        } else if (currentScroll >= windowHeight + 100) {
          $('.project-header').addClass('project-header--hidden');
        } else {
          $('.project-header').removeClass('project-header--hidden');
        }

        if (
          bodyHeight -
            (currentScroll + windowHeight + windowHeight / 2 + footerHeight) <=
          0
        ) {
          $('.owl-nav--custom').removeClass('owl-nav--hide');
        } else if (currentScroll > windowHeight / 2) {
          $('.owl-nav--custom').addClass('owl-nav--hide');
        } else {
          $('.owl-nav--custom').removeClass('owl-nav--hide');
        }
      });
    }
  })();

  $(window).on('load', function () {
    $('.js-masonry').masonry({
      // set itemSelector so .grid-sizer is not used in layout
      itemSelector: '.post-item',
      // use element for option
      columnWidth: '.post-item',
      percentPosition: true,
    });
  });

  $(document).ajaxComplete(function () {});

  window.onpopstate = function (event) {
    inAnimation = false;

    if (event.state !== null) {
      document.title = currentDocTitle;
      $('body').attr('class', currentDocClass);
      $('#wrapper').html(event.state.content);
      docReadyFunctions();
    } else {
      event.preventDefault();
      return false;
    }
  };

  // project header opacity

  $(window).on('scroll', function () {
    var scrollTop = $(window).scrollTop();
    var docHeight = $(document).height();
    var winHeight = $(window).height();
    var scrollPercent = scrollTop / (docHeight - winHeight);
    var scrollPercentRounded = Math.round(scrollPercent * 100) / 100;

    var opacity = 1 - scrollPercentRounded;

    opacity = Math.max(0, Math.min(1, opacity));

    $('#hide-on-scroll').css('opacity', opacity);
  });
})(jQuery); // Fully reference jQuery after this point.

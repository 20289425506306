(function ($) {
  // Functions

  var new_map, add_marker, center_map, overlayMap;

  /*
   *  new_map
   *
   *  This function will render a Google Map onto the selected jQuery element
   *
   *  @type	function
   *  @date	8/11/2013
   *  @since	4.3.0
   *
   *  @param	$el (jQuery element)
   *  @return	n/a
   */

  function CustomMarker(latlng, map, args) {
    this.latlng = latlng;
    this.args = args;
    this.setMap(map);
  }

  CustomMarker.prototype = new google.maps.OverlayView();

  CustomMarker.prototype.draw = function () {
    var self = this;
    var div = this.div;

    if (!div) {
      div = this.div = document.createElement('div');
      div.className = 'map-marker down-arrow down-arrow--black';
      div.style.position = 'absolute';

      var panes = this.getPanes();
      panes.overlayImage.appendChild(div);
    }

    var point = this.getProjection().fromLatLngToDivPixel(this.latlng);

    if (point) {
      div.style.left = point.x + 'px';
      div.style.top = point.y + 'px';
    }
  };

  CustomMarker.prototype.remove = function () {
    if (this.div) {
      this.div.parentNode.removeChild(this.div);
      this.div = null;
    }
  };

  CustomMarker.prototype.getPosition = function () {
    return this.latlng;
  };

  overlayMap = function ($element) {
    var historicalOverlay;

    var mapLat = parseFloat($element.attr('data-lat'));
    var mapLng = parseFloat($element.attr('data-lng'));
    var mapZoom = parseFloat($element.attr('data-zoom'));

    console.log('mapLat:', mapLat);
    console.log('mapLng:', mapLng);
    console.log('mapZoom:', mapZoom);

    // Check if we have valid location data
    if (isNaN(mapLat) || isNaN(mapLng)) {
      console.error('Invalid latitude or longitude');
      return;
    }

    const map = new google.maps.Map($element[0], {
      zoom: mapZoom ? mapZoom : 14,
      center: { lat: mapLat, lng: mapLng },
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      scrollwheel: false,
    });

    // // Add a marker for the location
    // new google.maps.Marker({
    //   position: { lat: mapLat, lng: mapLng },
    //   map: map,
    // });

    map.set('styles', [
      {
        featureType: 'water',
        elementType: 'geometry',
        stylers: [
          {
            color: '#e9e9e9',
          },
          {
            lightness: 17,
          },
        ],
      },
      {
        featureType: 'landscape',
        elementType: 'geometry',
        stylers: [
          {
            color: '#f5f5f5',
          },
          {
            lightness: 20,
          },
        ],
      },
      {
        featureType: 'road.highway',
        elementType: 'geometry.fill',
        stylers: [
          {
            color: '#ffffff',
          },
          {
            lightness: 17,
          },
        ],
      },
      {
        featureType: 'road.highway',
        elementType: 'geometry.stroke',
        stylers: [
          {
            color: '#ffffff',
          },
          {
            lightness: 29,
          },
          {
            weight: 0.2,
          },
        ],
      },
      {
        featureType: 'road.arterial',
        elementType: 'geometry',
        stylers: [
          {
            color: '#ffffff',
          },
          {
            lightness: 18,
          },
        ],
      },
      {
        featureType: 'road.local',
        elementType: 'geometry',
        stylers: [
          {
            color: '#ffffff',
          },
          {
            lightness: 16,
          },
        ],
      },
      {
        featureType: 'poi',
        elementType: 'geometry',
        stylers: [
          {
            color: '#f5f5f5',
          },
          {
            lightness: 21,
          },
        ],
      },
      {
        featureType: 'poi.park',
        elementType: 'geometry',
        stylers: [
          {
            color: '#dedede',
          },
          {
            lightness: 21,
          },
        ],
      },
      {
        elementType: 'labels.text.stroke',
        stylers: [
          {
            visibility: 'on',
          },
          {
            color: '#ffffff',
          },
          {
            lightness: 16,
          },
        ],
      },
      {
        elementType: 'labels.text.fill',
        stylers: [
          {
            saturation: 36,
          },
          {
            color: '#333333',
          },
          {
            lightness: 40,
          },
        ],
      },
      {
        elementType: 'labels.icon',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'transit',
        elementType: 'geometry',
        stylers: [
          {
            color: '#f2f2f2',
          },
          {
            lightness: 19,
          },
        ],
      },
      {
        featureType: 'administrative',
        elementType: 'geometry.fill',
        stylers: [
          {
            color: '#fefefe',
          },
          {
            lightness: 20,
          },
        ],
      },
      {
        featureType: 'administrative',
        elementType: 'geometry.stroke',
        stylers: [
          {
            color: '#fefefe',
          },
          {
            lightness: 17,
          },
          {
            weight: 1.2,
          },
        ],
      },
    ]);

    var imgUrl = $element.attr('data-img');

    // If imgUrl is present, create and set the overlay
    if (imgUrl) {
      var imgNorth = parseFloat($element.attr('data-north'));
      var imgSouth = parseFloat($element.attr('data-south'));
      var imgEast = parseFloat($element.attr('data-east'));
      var imgWest = parseFloat($element.attr('data-west'));

      console.log('imgNorth:', imgNorth);
      console.log('imgSouth:', imgSouth);
      console.log('imgEast:', imgEast);
      console.log('imgWest:', imgWest);
      console.log('imgUrl:', imgUrl);

      // Check if all bounds are valid numbers
      if (
        !isNaN(imgNorth) &&
        !isNaN(imgSouth) &&
        !isNaN(imgEast) &&
        !isNaN(imgWest)
      ) {
        const imageBounds = {
          north: imgNorth,
          south: imgSouth,
          east: imgEast,
          west: imgWest,
        };

        try {
          historicalOverlay = new google.maps.GroundOverlay(
            imgUrl,
            imageBounds
          );
          historicalOverlay.setMap(map);
          console.log('Overlay added successfully');
        } catch (error) {
          console.error('Error creating GroundOverlay:', error);
        }
      } else {
        console.error('Invalid image bounds');
      }
    } else {
      console.log(
        'No overlay image URL provided, displaying custom marker at map center'
      );

      var latlng = new google.maps.LatLng(mapLat, mapLng);

      // Create a hidden marker (for centering purposes)
      var marker = new google.maps.Marker({
        position: latlng,
        map: map,
      });
      marker.setVisible(false);

      // Create and add the CustomMarker
      var overlay = new CustomMarker(latlng, map, null);

      // Center the map on the marker
      map.setCenter(latlng);
    }

    return map;
  };

  new_map = function ($el) {
    // var
    var $markers = $el.find('.marker');

    // vars
    var args = {
      zoom: 14,
      center: new google.maps.LatLng(0, 0),
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      scrollwheel: false,
    };

    // create map
    var map = new google.maps.Map($el[0], args);

    // add a markers reference
    map.markers = [];

    // add markers
    $markers.each(function () {
      add_marker($(this), map);
    });

    // set styles
    map.set('styles', [
      {
        featureType: 'all',
        elementType: 'labels.text.fill',
        stylers: [{ saturation: 36 }, { color: '#333333' }, { lightness: 40 }],
      },
      {
        featureType: 'all',
        elementType: 'labels.text.stroke',
        stylers: [
          { visibility: 'on' },
          { color: '#ffffff' },
          { lightness: 16 },
        ],
      },
      {
        featureType: 'all',
        elementType: 'labels.icon',
        stylers: [{ visibility: 'off' }],
      },
      {
        featureType: 'administrative',
        elementType: 'geometry.fill',
        stylers: [{ color: '#fefefe' }, { lightness: 20 }],
      },
      {
        featureType: 'administrative',
        elementType: 'geometry.stroke',
        stylers: [{ color: '#fefefe' }, { lightness: 17 }, { weight: 1.2 }],
      },
      {
        featureType: 'landscape',
        elementType: 'geometry',
        stylers: [{ lightness: 20 }, { visibility: 'on' }],
      },
      {
        featureType: 'landscape.man_made',
        elementType: 'geometry',
        stylers: [{ visibility: 'on' }],
      },
      {
        featureType: 'landscape.man_made',
        elementType: 'geometry.fill',
        stylers: [{ saturation: '-100' }, { color: '#f4f4f4' }],
      },
      {
        featureType: 'landscape.man_made',
        elementType: 'geometry.stroke',
        stylers: [{ color: '#d2d2d2' }],
      },
      {
        featureType: 'poi',
        elementType: 'geometry',
        stylers: [{ color: '#f1f1f1' }, { lightness: 21 }],
      },
      {
        featureType: 'poi.business',
        elementType: 'geometry.stroke',
        stylers: [{ color: '#ffb4b4' }, { visibility: 'off' }],
      },
      {
        featureType: 'poi.park',
        elementType: 'geometry',
        stylers: [{ color: '#dedede' }, { lightness: 21 }],
      },
      {
        featureType: 'road.highway',
        elementType: 'geometry.fill',
        stylers: [{ color: '#ffffff' }, { lightness: 17 }],
      },
      {
        featureType: 'road.highway',
        elementType: 'geometry.stroke',
        stylers: [{ color: '#ffffff' }, { lightness: 29 }, { weight: 0.2 }],
      },
      {
        featureType: 'road.arterial',
        elementType: 'geometry',
        stylers: [{ color: '#ffffff' }, { lightness: 18 }],
      },
      {
        featureType: 'road.local',
        elementType: 'geometry',
        stylers: [{ color: '#ffffff' }, { lightness: 16 }],
      },
      {
        featureType: 'transit',
        elementType: 'geometry',
        stylers: [{ color: '#f2f2f2' }, { lightness: 19 }],
      },
      {
        featureType: 'water',
        elementType: 'geometry',
        stylers: [{ color: '#e9e9e9' }, { lightness: 17 }],
      },
    ]);

    // center map
    center_map(map);

    // return
    return map;
  };

  /*
   *  add_marker
   *
   *  This function will add a marker to the selected Google Map
   *
   *  @type	function
   *  @date	8/11/2013
   *  @since	4.3.0
   *
   *  @param	$marker (jQuery element)
   *  @param	map (Google Map object)
   *  @return	n/a
   */

  add_marker = function ($marker, map) {
    // var
    var latlng = new google.maps.LatLng(
      $marker.attr('data-lat'),
      $marker.attr('data-lng')
    );
    var iconBase = theme_base + '/dist/images/';
    var iconUrl = $marker.attr('data-marker');
    var iconWidth = $marker.attr('data-marker_width');
    var iconHeight = $marker.attr('data-marker_height');
    var icon = new google.maps.MarkerImage(
      iconUrl,
      null,
      null,
      null,
      new google.maps.Size(iconWidth / 2, iconHeight / 2)
    );
    var marker;

    if ($('body').hasClass('page-template-template-about')) {
      marker = new google.maps.Marker({
        position: latlng,
        map: map,
      });
      map.markers.push(marker);
      marker.setVisible(false);

      overlay = new CustomMarker(latlng, map, null);
    } else {
      marker = new google.maps.Marker({
        position: latlng,
        map: map,
        icon: icon,
      });
      map.markers.push(marker);
      marker.setVisible(false);
    }
    /*
	if(iconUrl === "") {
		marker = new google.maps.Marker({
			position	: latlng,
			map			: map,
		});
		map.markers.push( marker );
		marker.setVisible(false);

		overlay = new CustomMarker(
			latlng,
			map,
			null
		);
	} else {
		marker = new google.maps.Marker({
			position	: latlng,
			map			: map,
			icon        : icon
		});
		map.markers.push( marker );
	}
	*/
  };

  /*
   *  center_map
   *
   *  This function will center the map, showing all markers attached to this map
   *
   *  @type	function
   *  @date	8/11/2013
   *  @since	4.3.0
   *
   *  @param	map (Google Map object)
   *  @return	n/a
   */

  center_map = function (map) {
    // vars
    var bounds = new google.maps.LatLngBounds();

    // loop through all markers and create bounds
    $.each(map.markers, function (i, marker) {
      var latlng = new google.maps.LatLng(
        marker.position.lat(),
        marker.position.lng()
      );

      bounds.extend(latlng);
    });

    // only 1 marker?
    if (map.markers.length === 1) {
      // set center of map
      map.setCenter(bounds.getCenter());
      map.setZoom(16);
    } else {
      // fit to bounds
      map.fitBounds(bounds);
    }
  };

  /*
   *  document ready
   *
   *  This function will render each map when the document is ready (page has loaded)
   *
   *  @type	function
   *  @date	8/11/2013
   *  @since	5.0.0
   *
   *  @param	n/a
   *  @return	n/a
   */
  // global var
  var map = null;

  $(document).ready(function () {
    $('.acf-map').each(function () {
      // create map
      map = new_map($(this));
    });
    $('#map').each(function () {
      // create map
      map = overlayMap($(this));
    });
  });

  $(document).ajaxComplete(function () {
    $('.acf-map').each(function () {
      // create map
      map = new_map($(this));
    });
  });
})(jQuery);
